.loader {
    margin: 0 auto !important;
    float: none !important;
    width: 48px;
    height: 48px;
    border: 5px solid;
    border-color: rgb(92, 94, 159) transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }
  
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 