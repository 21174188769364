:root {
  --custom-primary: #;
  --custom-secondary: #6c757d;
  --custom-success: #3dd287;
  --custom-danger: #f22929;
  --custom-warning: #ffcc00;
  --custom-info: #3399ff;
  --custom-light: #f0f0f0;
  --custom-dark: #333333;
}

:root {
  --primary: var(--custom-primary);
  --secondary: var(--custom-secondary);
  --success: var(--custom-success);
  --danger: var(--custom-danger);
  --warning: var(--custom-warning);
  --info: var(--custom-info);
  --light: var(--custom-light);
  --dark: var(--custom-dark);
}

.boxshadow-card{
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
}
.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgb(92, 94, 159);
  --bs-btn-border-color: rgb(92, 94, 159);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #313a5a;
  --bs-btn-hover-border-color: #313a5a;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #313a5a;
  --bs-btn-active-border-color: #313a5a;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgb(92, 94, 159);
  --bs-btn-disabled-border-color: rgb(92, 94, 159);
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: rgb(92, 94, 159);
}

.nav-link:focus,
.nav-link:hover {
  color: #313a5a;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(92, 94, 159);
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: rgb(92, 94, 159);
  border-width: 2px !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(141, 64, 74, 0);
}

.form-select:focus {
  border-color: rgb(92, 94, 159);
  border-width: 2px !important;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(141, 64, 74, 0);
}

.css-t3ipsp-control:hover {
  border-color: rgb(92, 94, 159) !important;
}

.css-t3ipsp-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: rgb(92, 94, 159) !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 0.1px !important;
  box-shadow: 0 0 0 1px rgb(92, 94, 159) !important;
  box-sizing: border-box;
}


.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.0rem rgba(var(--bs-danger-rgb),.0);
}

.date_picker{
  width: 200%;
}

.datePicker {
  width: 100%;
}
 
.placeholder-color::placeholder {
  color: #808080;
}

/* div{
 text-transform: capitalize;
} */

